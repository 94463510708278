import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

import SEOComponent from "components/seo"
import Layout from "components/site-structure/layout"

const Bedienung = () => (
  <Layout>
    <SEOComponent
      description="So bedienen Sie das Mölkky online Scoreboard:"
      title="Bedienungsanleitung"
    />
    <div className="container">
      <div className="section">
        <h1 className="title is-3">Bedienungsanleitung</h1>
      </div>

      <div className="section">
        <h2 className="title is-4">Spielbeginn:</h2>

        <p>
          Trage zunächst jeden Spieler nacheinander ein. Die Reihenfolge in der
          ersten Runde wird von der Reihenfolge bestimmt, in der die Spieler
          eingetragen sind. Sollte sich ein Spieler dazu entscheiden, doch nicht
          mitzuspielen, nachdem er eingetragen wurde, könnt Ihr ihn oder sie
          über den{" "}
          <button aria-label="delete-button" className="delete"></button>-Button
          aus der Liste entfernen.
        </p>

        <h2 className="title is-4">Spielablauf:</h2>
        <p>
          Sobald ein Spieler geworfen und Punkte (auch 0) erziehlt hat, wähle
          die entsprechende Punktzahl aus der Liste aus. Klicke anschließend auf
          den Button &nbsp;
          <button className="button is-small is-white">
            <span className="icon is-small">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </button>
          , um die Punkte einzutragem. Solltest du dich vertan haben, kannst du
          die Punkte des letzten Wurfes mit dem Button &nbsp;
          <button className="button is-small is-warning">
            Rückgängig
          </button>{" "}
          korrigieren. <br />
          Wenn nur noch ein Spieler übrig ist, kann er oder sie noch bis Runde
          26 zuende spielen, oder Ihr brecht das Spiel mit dem Button{" "}
          <button className="button is-small is-danger">Abbrechen</button> ab.
        </p>
        <h2 className="title is-4">Spielende:</h2>
        <p>
          Am Ende des Spiels wird Euch eine Spielübersicht angezeigt. Hier könnt
          Ihr sehen, wer die 50 Punkte geschafft hat, wer duschen gehen musste
          und wer es in 26 Runden nicht geschafft hat, das Spiel zu beenden.{" "}
          <br />
          Außerdem findet Ihr hier eine Spielzusammenfassung mit allen Würfen
          als Tabelle.
        </p>
        <p>
          Mit dem Button{" "}
          <button className="button is-small is-warning">
            Erneut spielen!
          </button>{" "}
          &nbsp; könnt Ihr das Spiel mit den selben Spielern neu starten, oder
          jemanden hinzufügen, oder aus der Liste löschen.
        </p>
      </div>
    </div>
  </Layout>
)

export default Bedienung
